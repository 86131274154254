import Box from '@gtg/components/box'
import Container from '@gtg/components/container'
import Footer from '@gtg/components/footer'
import GlobalWrapper from '@gtg/components/globalWrapper'
import Nav from '@gtg/components/nav'
import Seo from '@gtg/components/seo'
import media from '@gtg/utils/media-queries'
import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

const Section = styled.section`
  min-height: calc(100vh - 321px);
  ${media.sm`
    min-height: calc(100vh - 439px);
  `}
`

const Agb = () => {
  return (
    <GlobalWrapper>
      <Seo title="404" />
      <Nav caps wMax="xl" withBorder />
      <Section>
        <Container>
          <Box pxMax="md" pyMax="lg">
            <h1>404 - Seite existiert nicht.</h1>
            <Link to="/">Zurück zur Hauptseite</Link>
          </Box>
        </Container>
      </Section>
      <Footer background="#0c1821" />
    </GlobalWrapper>
  )
}

export default Agb
